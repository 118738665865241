export const localhostUrl = "http://localhost:8000/api/";
export const deployedUrl = "https://backend-live.card-console.com/api/";
export const stagingUrl = "https://backend-dev.card-console.com/api/";
export const stagingdevUrl = "https://backend-staging.card-console.com/api/";

let url = window.location.href;
 if (url.includes("localhost")) url = localhostUrl;
 else if(url.includes("dev.control")) url= stagingUrl;
 else if(url.includes("staging.control")) url= stagingdevUrl;
 else if(url.includes("control-center")) url= deployedUrl;
else 
url = "";

export const Env = {
  AuthApiUrl: url + "auth/",
  AccountApiUrl: url + "accounts/",
  UserApiUrl: url + "users/",
  UploadDocuments: url + "Upload/",
  ClientAdmin: url + "Client/",
  CardsApiUrl: url + "card/",
  CardHolderApiUrl: url + "cardholder/",
  ClaimRoleAuth: url + "ClaimRoleAuth/",
  DepositApiUrl: url + "deposits/",
  SettingsApiUrl: url + "settings/",
  HelpApiUrl: url + "help/",
  TransactionApiUrl: url + "transaction/",
  SuperClientApiUrl: url + "superclient/",
  IBANApiUrl: url + "IbanAccounts/",
};
